<template>
  <div
    :class="{
      'v-menu-description-ingredients--from-popup': fromPopup
    }"
  >
    <span
      v-for="ingredient in [...product.RemovableIngredients].sort(
        (a, b) => a.Index - b.Index
      )"
      :key="`${product.ID}-${ingredient.Index}-ingredient`"
      class="v-menu-description-ingredients__ingredient"
      :class="{
        'v-menu-description-ingredients__ingredient--removable': ingredient.ModifierId,
        'v-menu-description-ingredients__ingredient--removed':
          ingredient.ModifierId && isIngredientRemoved(ingredient.ModifierId)
      }"
    >
      <span
        class="v-menu-description-ingredients__ingredient-title"
        v-html="
          ingredient.ModifierId ? clean(ingredient.Title) : sanitize(ingredient.Title)
        "
      />
      <span
        v-if="ingredient.ModifierId"
        class="v-menu-description-ingredients__toggle"
        @click="handleToggleIngredient(ingredient.ModifierId)"
      >
        <icon-old-general-plus-in-circle class="v-menu-description-ingredients__icon" />
      </span>
      <span v-html="sanitize(`${ingredient.Delimiter} `)" />
    </span>
  </div>
</template>

<script setup lang="ts">
import type { ProductInList } from '~types/menuStore'

import type { GUID } from '@arora/common'

const { product } = defineProps<{
  product: ProductInList
}>()

const menuStore = useMenuStore()

const { fromPopup } = useInstance()
const { clean, sanitize } = useI18nSanitized()

const isIngredientRemoved = (modID: GUID | null): boolean => {
  const modsOnProduct = menuStore.SelectedModifiersPerProduct.get(product.ID)
  if (!modID || !modsOnProduct || !modsOnProduct[modID]) return false

  return modsOnProduct[modID] > 0
}

const handleToggleIngredient = (modID: GUID): void => {
  const modsOnProduct = menuStore.SelectedModifiersPerProduct.get(product.ID)

  if (!modsOnProduct) return

  modsOnProduct[modID] = modsOnProduct[modID] ? 0 : 1
}
</script>

<style lang="scss">
.v-menu-description-ingredients {
  $ingredient: '.v-menu-description-ingredients';

  line-height: 150%;

  &--from-popup {
    font-size: 1.1rem;
  }

  &__ingredient {
    &--removable {
      display: inline-block;
      margin-right: 3px;

      #{$ingredient}__ingredient-title {
        background:
          linear-gradient(to right, currentColor, currentColor),
          linear-gradient(to right, currentColor, currentColor);
        background-size:
          100% 1px,
          0 1px;
        background-position:
          left bottom,
          left center;
        background-repeat: no-repeat;
        transition: background-size 500ms;
      }
    }

    &--removed {
      #{$ingredient}__ingredient-title {
        background-size:
          0 1px,
          100% 1px;
      }

      #{$ingredient}__toggle {
        svg {
          opacity: 0.6;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__toggle {
    margin-left: 5px;
    margin-right: 1px;
    cursor: pointer;

    svg {
      vertical-align: middle;
      opacity: 0.3;
      transition: 0.5s;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}
</style>
